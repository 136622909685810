<style scoped>
.pagebgimg{
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100%;height: 100%;
  }
  .pagebgimg>img{
    width: 100%;height: 100%;
  }
  .Jump-login_div{
    text-align: center;
    margin-top: 65%;
  }
  .Jump-login_div_img{
        margin-bottom: 7%;
  }
  .Jump-login_div_text{
    font-size: 14px;
    font-weight: 400;
    color: rgba(206, 205, 216, .78);
  }
</style>
<template>
  <div class="Jump-login">
    <div class="Jump-login_div">
      <img class="Jump-login_div_img" width="130" height="130" src="../../assets/image/Jumploginimg.png" alt="">
      <div class="Jump-login_div_text">
        嘿呦，努力加载中...
      </div>
    </div>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/loginbgsm.png'),
      username: '',
      smscode: '',
      params: ''

    }
  },
  created () {
    this.username = this.$route.query.username
    this.smscode = this.$route.query.smscode
    this.params = this.$route.query.params
    this.loginfn()
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/loginbgbig.png')
    }
  },
  methods: {
    loginfn () {
      if (this.username != null && this.smscode != null && this.username.length > 0 && this.smscode.length > 0) {
        // console.log("mxsj login")
        setTimeout((e) => {
          this.$store.dispatch('home/loginCode1', {
            'username': this.username,
            'smscode': this.smscode,
            'params': this.params,
            'token': '',
            'type': 'web',
            'router': this.$router,
            'toast': this.$toast
          })
        }, 1000)
      } else {
        // console.log(123)
        setTimeout((e) => {
          window.location.href = '/login'
        }, 3000)
      }
    }
  }
}
</script>
